<template>
  <div id="tender-list">
    <v-card>
      <StandardCardHeader
        :go-to-link="'admin-diary-create'"
        :title="'Diary list'"
        :button-text="'Create'"
        :show-action-button="false"
        :link-button-color="'success'"
      />
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="$t('Type to search')"
            clearable
            outlined
            hide-details
            dense
            class="tender-search mb-4"
            @keyup="getDataFromApi"
            @click:clear.prevent="resetSearch()"
          >
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            v-model="typeFilter"
            :placeholder="$t('Type filter')"
            :items="$store.state.diaryTypes"
            item-value="key"
            :item-text="item => $t(item.name)"
            outlined
            dense
            clearable
            hide-details
            class="mb-4"
            @change="getDataFromApi"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            v-model="userFilter"
            :placeholder="$t('User filter')"
            :items="$store.state.users"
            item-value="id"
            item-text="name"
            outlined
            dense
            clearable
            hide-details
            class="mb-4"
            @change="getDataFromApi"
          ></v-select>
        </v-col>
      </v-row>

      <v-data-table
        v-model="selectedRows"
        dense
        :headers="tableColumns"
        :items="diaryListTable"
        :items-per-page="getItemsPerPageFromLS()"
        :options.sync="options"
        :server-items-length="totalDiaries"
        :loading="loading"
        :no-results-text="$t('noResults')"
        :no-data-text="$t('noResults')"
        :footer-props="{
          'items-per-page-text': '#',
          'items-per-page-options': [10, 20, 50, 100],
          'page-text': `{0}-{1} ${$t('of')} {2}`
        }"
      >
        <template #[`item.title`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'admin-diary-edit', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.title }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.description`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'admin-diary-edit', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.description }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.type`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ $t(`${item.type}`) }}
            </div>
          </div>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'admin-diary-edit',params:{id:item.id}}"
                link
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentEditOutline }}
                  </v-icon>
                  <span>{{ $t('Edit') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="!item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="deleteDiary(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>{{ $t('Delete') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="restoreDiary(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileRestoreOutline }}
                  </v-icon>
                  <span>{{ $t('Restore') }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentEditOutline,
  mdiFileDocumentOutline,
  mdiFileRestoreOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'

// sidebar
import axiosIns from '@axios'
import statusService from '@/views/shared/statusService'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'
import searchMixin from '@/mixins/searchMixin'
import { getItemsPerPageFromLS } from '@/services/localStorageService'

export default {
  components: { StandardCardHeader },
  mixins: [searchMixin],
  data() {
    const selectedRows = []
    const diaryListTable = []
    const totalDiaries = 0
    const loading = true
    const options = {}

    const tableColumns = [
      {
        text: this.$t('Title'),
        value: 'title',
      },
      {
        text: this.$t('Description'),
        value: 'description',
      },
      {
        text: this.$t('User'),
        value: 'user.name',
      },
      {
        text: this.$t('Type'),
        value: 'type',
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        align: 'center',
        sortable: true,
      },
    ]

    return {
      options,
      loading,
      typeFilter: '',
      userFilter: null,
      tableColumns,
      diaryListTable,
      selectedRows,
      totalDiaries,
      icons: {
        mdiFileDocumentEditOutline,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiFileRestoreOutline,
      },
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },

  created() {
    this.resolveStatusVariant = statusService.resolveStatusVariant.bind(this)
  },

  methods: {
    getItemsPerPageFromLS,
    changeStatus(diary) {
      const data = {
        status: diary.status,
      }
      axiosIns({
        method: 'PUT',
        data,
        url: `/admin/diaries/${diary.id}`,
      })
        .then(res => {
          this.snackbarMessage = this.$t('Data successfully saved')
          this.snackbar = true
          this.diaryId = res.data.diary.id
        })
        .catch(() => {
          this.snackbarMessage = this.$t('An error occurred')
          this.snackbar = true
        })
    },
    getDataFromApi() {
      localStorage.setItem('itemsPerPage', `${this.options.itemsPerPage}`)
      this.loading = true
      const orderBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : 'id'
      const orderDirection = this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : 'true'
      const search = this.searchQuery ?? ''
      const user = this.userFilter ?? ''
      const type = this.typeFilter ?? ''
      axiosIns.get(`/admin/diaries?page=${this.options.page}&perPage=${this.options.itemsPerPage}&orderBy=${orderBy}&orderDirection=${orderDirection}&search=${search}&type=${type}&userId=${user}`)
        .then(res => {
          this.totalDiaries = res.data.diaries.total
          this.diaryListTable = res.data.diaries.data.map(diary => ({
            ...diary,
            user: {
              ...diary.user,
              name: diary.user ? `${diary.user.first_name} ${diary.user.last_name}` : undefined,
            },
          }))
          this.loading = false
        })
        .catch()
    },
    deleteDiary(id) {
      axiosIns.delete(`/admin/diaries/${id}`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
    restoreDiary(id) {
      axiosIns.put(`/admin/diaries/${id}/restore`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
  },
}
</script>
